<template>
  <b-row id="calendar" class="calendar mt-4">
    <b-col>
      <FullCalendar ref="fullCalendar" :options="calendarOptions">
        <template #eventContent="{ event }">
          <div :id="`${event.id}${event.start}`">
            <span>
              {{
                `${event.extendedProps.slotStartTime} - ${event.extendedProps.slotEndTime}`
              }}
            </span>
            <br />
            <span class="text-capitalize">
              {{ event.title }}
            </span>
          </div>
          <b-popover
            :target="`${event.id}${event.start}`"
            placement="top"
            triggers="hover"
            container="calender"
          >
            <template #title>
              <div class="d-flex align-items-center">
                <font-awesome-icon icon="door-open" class="mr-2" />
                {{ event.title }}
              </div>
            </template>
            <div>
              <div class="flex-center-between">
                <span class="d-flex align-items-center">
                  <font-awesome-icon icon="clock" class="mr-2" />
                  {{ $t('Time') }}:
                  {{
                    `${event.extendedProps.slotStartTime} - ${event.extendedProps.slotEndTime}`
                  }}
                </span>
              </div>
              <div class="flex-center-between">
                <span class="d-flex align-items-center">
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="mr-2"
                  />
                  {{ $t('validFrom') }}:
                  {{ event.extendedProps.validFrom }}
                </span>
              </div>
              <div
                v-if="event.extendedProps.validUntil"
                class="flex-center-between"
              >
                <span class="d-flex align-items-center">
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="mr-2"
                  />
                  {{ $t('validUntil') }}:
                  {{ event.extendedProps.validUntil }}
                </span>
              </div>
              <div
                v-if="event.extendedProps.userpools"
                class="flex-center-between"
              >
                <span class="d-flex align-items-center">
                  <font-awesome-icon icon="users" class="mr-2" />
                  {{ $t('userpools') }}:
                  {{ event.extendedProps.userpools }}
                </span>
              </div>
            </div>
          </b-popover>
        </template>
      </FullCalendar>
    </b-col>
  </b-row>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import allLocales from '@fullcalendar/core/locales-all'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment-timezone'

export default {
  name: 'CalendarView',
  components: {
    FullCalendar
  },
  props: {
    slots: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    calendarOptions: {
      locales: allLocales,
      firstDay: 1,
      fixedWeekCount: false,
      initialView: 'timeGridWeek',
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridWeek,timeGridDay'
      },
      views: {
        dayGridMonth: {
          titleFormat: { day: '2-digit', month: 'numeric' },
          dayHeaderFormat: {
            weekday: 'long'
          }
        }
      },
      events: [],
      editable: true,
      selectable: true
    },
    transformedSlots: [],
    currentEvents: []
  }),
  watch: {
    slots: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data && data.length) {
          this.transformSlots(data)
        } else {
          this.transformedSlots = []
        }
      }
    },
    transformedSlots: {
      immediate: true,
      deep: true,
      handler(val) {
        this.calendarOptions.events = val || []
      }
    }
  },
  created() {
    this.calendarOptions.select = this.handleDateSelect
    this.calendarOptions.eventClick = this.handleEventClick
    this.calendarOptions.eventsSet = this.handleEvents
    this.calendarOptions.locale = this.$i18n.locale
  },
  methods: {
    handleDateSelect(selectInfo) {
      this.$emit('create-event')
    },
    handleEventClick({ event }) {
      const date = event.startStr
      const eventToEdit = this.slots.find(
        slot => Number(slot.id) === Number(event.id)
      )
      if (eventToEdit) {
        eventToEdit.calendarStartDate = date
        eventToEdit.startDate = event.startStr
        this.$emit('edit-event', eventToEdit)
      }
    },
    transformSlots(incomingSlotsData) {
      const transformedSlots = []
      incomingSlotsData.forEach(slot => {
        if (slot.showOnCalendar) {
          const fullDay =
            slot.start_time === '00:00' && slot.end_time === '23:59'
          transformedSlots.push({
            id: slot.id,
            title: slot.roomName,
            textColor: 'white',
            backgroundColor: slot.backgroundColor,
            allDay: fullDay,
            startTime: fullDay ? null : slot.start_time,
            endTime: fullDay ? null : slot.end_time,
            startRecur: `${this.dateFormatter(
              slot.valid_from,
              'T',
              0
            )}T${slot.start_time}`,
            endRecur: slot.valid_until
              ? `${this.dateFormatter(slot.valid_until, 'T', 0)}T${
                  slot.end_time
                }`
              : null,
            daysOfWeek: this.getDaysOfWeekWhereReservationIsAllowed(
              slot
            ),
            showOnCalendar: slot.showOnCalendar,
            // following key values are being used to show in tooltip
            userpools: Array.isArray(slot.userpools)
              ? slot.userpools.join(', ')
              : '',
            validFrom:
              moment(slot.valid_from).format('DD/MM/YYYY') || '',
            validUntil: slot.valid_until
              ? moment(slot.valid_until)
                  .utc()
                  .format('DD/MM/YYYY')
              : null,
            slotStartTime: slot.start_time || '',
            slotEndTime: slot.end_time || ''
          })
        }
      })
      this.transformedSlots = transformedSlots
    },

    /**
     * This function returns date after spliting based on the splitter
     * actualDate is the date which this function splits
     * splitter, we use this to split the date
     * index, after spliting we will have an array, index tells which index data we will return
     */
    dateFormatter(actualDate, splitter, index = 0) {
      return actualDate.split(splitter)[index]
    },

    /**
     *
     */
    getDaysOfWeekWhereReservationIsAllowed(slot) {
      const allowedDays = []
      if (slot.sunday) {
        allowedDays.push(0)
      }
      if (slot.monday) {
        allowedDays.push(1)
      }
      if (slot.tuesday) {
        allowedDays.push(2)
      }
      if (slot.wednesday) {
        allowedDays.push(3)
      }
      if (slot.thursday) {
        allowedDays.push(4)
      }
      if (slot.friday) {
        allowedDays.push(5)
      }
      if (slot.saturday) {
        allowedDays.push(6)
      }
      return allowedDays
    }
  }
}
</script>
<style>
.fc-event-main {
  overflow: hidden;
}
</style>
