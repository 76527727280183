<template>
  <b-modal
    id="choice-modal"
    hide-footer
    no-close-on-backdrop
    size="sm"
    :title="$t('chooseNextAction')"
    centered
  >
    <div
      class="d-flex align-items-center justify-content-between mt-1"
    >
      <b-button variant="success" @click="$emit('split', {})">
        {{ $t('split') }}
      </b-button>
      <b-button primary @click="$emit('edit', {})">
        {{ $t('edit') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ChoiceModal'
}
</script>
