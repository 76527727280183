<template>
  <div class="page-container">
    <div v-if="loading">
      <div class="loader">
        <b-spinner label="Spinning" />
      </div>
    </div>
    <section v-else class="section-settings">
      <div class="container">
        <alert />
        <b-tabs v-model="activeTab" pills class="map-tabs">
          <b-tab active>
            <template v-slot:title>
              <font-awesome-icon icon="calendar-alt" />
            </template>
            <b-row class="calendar">
              <template v-if="!isMobileDevice">
                <b-col cols="2" class="mt-4">
                  <h6>
                    {{ $t('availableRooms') }}
                  </h6>
                  <div class="filters scrollableContent">
                    <div>
                      <b-form-checkbox
                        id="checkboxAll"
                        v-model="checkAllFlag"
                        name="checkboxAll"
                        value="true"
                        unchecked-value="false"
                        class="m-2 slot-checkbox"
                        @change="toggleAll()"
                      >
                        {{ $t('selectAll') }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-for="selectedSlot in selectedSlots"
                        :key="selectedSlot.id"
                        v-model="selectedSlot.checked"
                        :style="{
                          '--color': selectedSlot.backgroundColor
                        }"
                        class="m-2 slot-checkbox"
                        @input="
                          selectedFilterValues(
                            selectedSlot.roomName,
                            $event
                          )
                        "
                      >
                        <span class="option-label">
                          {{ selectedSlot.roomName }}
                        </span>
                      </b-form-checkbox>
                    </div>
                  </div>
                </b-col>
                <!-- This if condition on calendar view is a hack to avoid screen flickering -->
                <b-col cols="10">
                  <CalendarView
                    v-if="activeTab === 0"
                    :slots="timeslots"
                    @create-event="createTimeslot"
                    @edit-event="editTimeslot"
                  />
                </b-col>
              </template>
              <div v-else class="mt-4 p-4">
                {{ $t('calendarViewErrorMessage') }}
              </div>
            </b-row>
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <font-awesome-icon icon="list" />
            </template>
            <div class="mt-4">
              <div class="row justify-content-md-center">
                <div class="col-lg-12 col-sm-12">
                  <timeslots-table
                    :items="filteredTimeSlots"
                    :columns="columns"
                    :actions="actions"
                    :hide-old-time-slots="true"
                    :origional-time-slots="timeslots"
                    @edit="editTimeslot"
                    @delete="
                      data => {
                        timeslot = data
                        $bvModal.show('modal-remove')
                      }
                    "
                    @create="createTimeslot"
                  />
                </div>
              </div>
              <timeslot-modal
                :timeslot="timeslot"
                @done="getTimeslots"
              />
              <split-slot-modal
                ref="splitSlotModal"
                :valid-from="currentCalendarDate"
                @done="getTimeslots"
              />
              <choice-modal
                @edit="showEditModal"
                @split="showSplitModal"
              />
              <b-modal
                id="modal-remove"
                :on-enter-key-press="() => deleteTimeslot(timeslot)"
                hide-footer
                :title="$t('remove')"
              >
                <div class="d-block text-center">
                  <p>
                    {{ $t('deleteTimeslotConfirmation') }}
                  </p>
                </div>
                <div class="d-flex justify-content-center mt-1">
                  <b-button
                    variant="danger"
                    @click="$bvModal.hide('modal-remove')"
                  >
                    {{ $t('no') }}
                  </b-button>
                  <b-button
                    class="ml-1"
                    primary
                    @click="deleteTimeslot(timeslot)"
                  >
                    {{ $t('yes') }}
                  </b-button>
                </div>
              </b-modal>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { find as _find } from 'lodash'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'

import OkkuApi from '@/services/OkkuApi'
import { SET_ALERT } from '@/store/modules/common/mutationTypes'

import Alert from '@/components/common/Alert'
import TimeslotsTable from '@/components/common/Table'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import TimeslotModal from '@/components/modals/TimeslotModal'
import SplitSlotModal from '@/components/modals/SplitSlotDatesModal'
import CalendarView from '@/components/timeslots/CalendarView'
import ChoiceModal from '@/components/modals/ChoiceModal'
import { colors } from '@/constants'

const DEFAULT_COLUMNS = [
  {
    class: '',
    header: 'room',
    key: 'roomName'
  },
  {
    class: '',
    header: 'startTime',
    key: 'start_time',
    hint: 'timezoneHint',
    hintParams: {}
  },
  {
    class: '',
    header: 'endTime',
    key: 'end_time',
    hint: 'timezoneHint',
    hintParams: {}
  },
  {
    class: '',
    header: 'validFrom',
    key: 'valid_from',
    transform(item) {
      return item.valid_from
        ? moment(item.valid_from)
            .tz('utc')
            .format('YYYY-MM-DD')
        : '-'
    },
    hint: 'timezoneHint',
    hintParams: {}
  },
  {
    class: '',
    header: 'validUntil',
    key: 'valid_until',
    transform(item) {
      return item.valid_until
        ? moment(item.valid_until)
            .tz('utc')
            .format('YYYY-MM-DD')
        : '-'
    },
    hint: 'timezoneHint',
    hintParams: {}
  },
  {
    class: '',
    header: 'availableOnDays',
    key: 'days',
    transform(item) {
      return `<div class='timeslot-days'>
        <span class='day-${item.monday}'>M</span>
        <span class='day-${item.tuesday}'>T</span>
        <span class='day-${item.wednesday}'>W</span>
        <span class='day-${item.thursday}'>T</span>
        <span class='day-${item.friday}'>F</span>
        <span class='day-${item.saturday}'>S</span>
        <span class='day-${item.sunday}'>S</span>
      </div>`
    },
    hintParams: {}
  },
  {
    class: '',
    header: 'userpools',
    key: 'userpools',
    transform(item) {
      return Array.isArray(item.userpools)
        ? item.userpools.join(', ')
        : '-'
    },
    hintParams: {}
  },
  {
    class: '',
    header: 'timezoneId',
    key: 'timezone_id',
    hintParams: {}
  }
]
const DEFAULT_ACTIONS = [
  {
    name: 'add',
    global: true,
    class: 'btn btn-secondary',
    event: 'create',
    icon: 'plus'
  },
  {
    name: 'edit',
    class: 'action link-edit text-primary',
    event: 'edit',
    icon: 'edit'
  },
  {
    name: 'remove',
    class: 'action link-remove',
    event: 'delete',
    icon: 'times'
  }
]
export default {
  name: 'TimeslotsPage',
  components: {
    Alert,
    TimeslotsTable,
    SplitSlotModal,
    TimeslotModal,
    // CombineModal,
    'b-modal': BootstrapModalHOC,
    CalendarView,
    ChoiceModal
  },
  data: () => ({
    timeslots: [],
    colors,
    timeslot: {},
    activeTab: 0,
    columns: DEFAULT_COLUMNS,
    actions: DEFAULT_ACTIONS,
    selectedSlots: [],
    loading: true,
    overLappedRooms: [],
    choiceModalId: 'choice-modal',
    checkAllFlag: true,
    filteredTimeSlots: [],
    currentCalendarDate: ''
  }),
  computed: {
    ...mapState('common', {
      organisation: '$organisation'
    }),
    ...mapState('common', ['ready', 'rooms']),
    ...mapGetters('common', {
      timezone: 'buildingTimezone',
      isMobileDevice: 'isMobileDevice',
      oldOpeningHours: 'getOldOpeningHours'
    })
  },
  watch: {
    async ready() {
      if (this.ready) {
        await this.getTimeslots()
      }
    },
    timezone() {
      this.columns = this.columns.map(column => {
        if (column.hintParams) {
          column.hintParams.timezone = this.timezone
        }
        return column
      })
    }
  },
  async created() {
    if (this.ready && this.organisation) {
      await this.getTimeslots()
    }
    this.columns = this.columns.map(column => {
      if (column.hintParams) {
        column.hintParams.timezone = this.timezone
      }
      return column
    })
  },
  methods: {
    /**
     * TODO: will remove comments once feature is ready for testing
     * Working
     * If checkbox is checked set showing status tru and vice versa
     */
    selectedFilterValues(selectedRoomName, event) {
      if (this.timeslots && this.timeslots.length) {
        this.timeslots = this.timeslots.map(timeslot => {
          if (timeslot.roomName === selectedRoomName) {
            timeslot.showOnCalendar = event
          }
          return timeslot
        })
      }
    },
    async getTimeslots() {
      await OkkuApi.getTimeslots().then(timeslots => {
        this.selectedSlots = []
        let index = 0
        const roomColors = {}
        this.timeslots = timeslots.map(item => {
          const room = _find(this.rooms, {
            id: item.object_id
          }) || { name: '-' }
          if (room) {
            item.roomName = room.name
            item.showOnCalendar = true
            item.checked = true
            if (!roomColors[item.object_id]) {
              if (index < this.colors.length - 1) {
                roomColors[item.object_id] = this.colors[index]
                index += 1
              } else {
                index = 0
                roomColors[item.object_id] = this.colors[index]
              }
            }
            item.backgroundColor = roomColors[item.object_id]

            // If this slot's room name is already present do not push again
            const found = this.selectedSlots.some(
              timeSlot => timeSlot.roomName === item.roomName
            )
            if (!found) this.selectedSlots.push(item)
          }
          return item
        })
        this.filteredTimeSlots = [...this.timeslots]
        if (this.oldOpeningHours) {
          const todayDate = moment().format('YYYY-MM-DD')
          this.filteredTimeSlots = this.filteredTimeSlots?.filter(
            item =>
              !item.valid_until ||
              moment(item.valid_until).format('YYYY-MM-DD') >=
                todayDate
          )
        }
      })
      this.loading = false
    },
    createTimeslot() {
      this.timeslot = {}
      this.$bvModal.show('timeslot-modal')
    },
    hideChoiceModal() {
      this.$bvModal.hide(this.choiceModalId)
    },
    showEditModal() {
      this.$bvModal.show('timeslot-modal')
      this.hideChoiceModal()
    },
    showSplitModal() {
      if (this.$refs.splitSlotModal) {
        this.$refs.splitSlotModal.resetState()
      }
      this.hideChoiceModal()
      this.currentCalendarDate =
        this.timeslot.calendarStartDate ||
        moment
          .tz('utc')
          .startOf('day')
          .toISOString()
      this.$bvModal.show('split-slot-modal')
    },
    async editTimeslot(timeslot) {
      this.timeslot = timeslot
      this.$bvModal.show(this.choiceModalId)
    },
    async deleteTimeslot(timeslot) {
      await OkkuApi.deleteTimeslot(timeslot.id)
        .then(async data => {
          await this.getTimeslots()
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('deleteSuccess'),
            variant: 'success'
          })
          this.$bvModal.hide('modal-remove')
        })
        .catch(error => {
          let { message } = error
          if (error.response && error.response.data) {
            message = error.response.data
          }
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: message,
            variant: 'danger'
          })
        })
    },
    toggleAll() {
      this.selectedSlots.forEach(selectedSlot => {
        selectedSlot.checked = this.checkAllFlag === 'false'
        this.selectedFilterValues(
          selectedSlot.roomName,
          selectedSlot.checked
        )
      })
    }
  }
}
</script>
<style lang="scss">
.timeslot-days {
  span {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: rgba(229, 229, 229, 0.5);
    border: 1px solid #5440a5;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 22px;
    font-weight: 500;
    box-sizing: border-box;
    &.day-true {
      background-color: #5440a5;
      color: #fff;
    }
  }
}

.calendar {
  height: 80%;
  .fc-scroller {
    .fc-day-grid-container {
      overflow: auto !important;
      height: auto !important;
    }
  }
}
.filters {
  display: block;
  height: 95%;
  overflow: scroll;
}
.loader {
  justify-content: center;
  margin-top: 15%;
  text-align: center;
}
.scrollableContent {
  height: 90%;
  margin: 0em;
  overflow-y: auto;
  overflow-x: auto;
}
</style>

<style scoped lang="scss">
@import '../../assets/scss/globals/vars.scss';
@import '../../assets/scss/globals/mixins.scss';
@import '../../assets/scss/globals/extend.scss';
.section-settings {
  padding: 2rem 0;
}
.map-tabs::v-deep {
  margin-top: 2rem;

  @include r(767) {
    padding-top: 0;
    & > div:first-of-type {
      display: flex;
      justify-content: center;
    }
  }
  .nav-link {
    color: $basecolor;
    &.active {
      background-color: $basecolor;
      color: #fff;
    }
  }

  .table-responsive {
    margin-top: 16px;
  }
}
.slot-checkbox .custom-control-label::before {
  border-color: var(--color) !important;
}
.slot-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 100%;
  }
}
</style>
