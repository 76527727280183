<template>
  <b-modal
    :id="modalId"
    :on-enter-key-press="split"
    hide-footer
    no-close-on-backdrop
    size="md"
    :title="$t('splitSlot.title')"
    centered
  >
    <div class="d-block">
      <div class="alert-warning mb-3">
        {{ $t('splitSlot.alertWarning') }}
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="form-group">
          <label
            >{{ $t('splitSlot.startFrom') }}
            <span
              v-b-tooltip.hover
              class="tip-icon"
              :title="$t('timezoneHint', { timezone })"
            >
              <font-awesome-icon icon="question-circle" />
            </span>
          </label>
          <datetime
            v-model="validFrom"
            class="form-control inp-style"
            type="date"
            :placeholder="$t('splitSlot.startDate')"
            zone="utc"
            :minute-step="1"
          />
        </div>
        <div class="form-group ml-1">
          <label
            >{{ $t('splitSlot.continuesAfter') }}
            <span
              v-b-tooltip.hover
              class="tip-icon"
              :title="$t('timezoneHint', { timezone })"
            >
              <font-awesome-icon icon="question-circle" />
            </span>
          </label>
          <datetime
            v-model="validUntil"
            class="form-control inp-style"
            :type="'date'"
            :placeholder="$t('splitSlot.continuesAfter')"
            zone="utc"
            :minute-step="1"
          />
        </div>
      </div>
      <div class="form-group">
        <label>{{ $t('rooms') }}</label>
        <multiselect
          v-model="selectedRooms"
          :loading="apiCallInProgress"
          :options="options"
          :multiple="true"
          label="roomName"
          track-by="roomName"
          :close-on-select="true"
          :placeholder="$t('selectRooms')"
        />
      </div>
      <b-form-invalid-feedback v-if="errors.genericErrors">
        {{ errors.genericErrors }}
      </b-form-invalid-feedback>
      <div class="d-flex align-items-center justify-content-end">
        <b-button variant="danger" class="mt-1" @click="closeModal()">
          {{ $t('cancel') }}
        </b-button>
        <b-button
          class="mt-1 ml-2"
          primary
          :disabled="!isFormValid"
          @click="split"
        >
          {{ $t('split') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { Datetime } from 'vue-datetime'

import OkkuApi from '@/services/OkkuApi'
import { SET_ALERT } from '@/store/modules/common/mutationTypes'

import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import Multiselect from 'vue-multiselect'
import 'vue-datetime/dist/vue-datetime.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'SplitSlotDatesModal',
  components: {
    Datetime,
    Multiselect,
    'b-modal': BootstrapModalHOC
  },
  props: {
    validFrom: {
      type: String,
      default: () => {
        moment
          .tz('utc')
          .startOf('day')
          .toISOString()
      }
    }
  },
  data: () => ({
    errors: {},
    data: {},
    selectedRooms: [],
    validUntil: null,
    modalId: 'split-slot-modal',
    overLappedRooms: [],
    apiCallInProgress: false
  }),
  computed: {
    ...mapGetters('common', {
      timezone: 'buildingTimezone'
    }),
    options() {
      return this.overLappedRooms || []
    },
    isFormValid() {
      return (
        !moment(this.validFrom).isAfter(moment(this.validUntil)) &&
        this.validFrom &&
        this.validUntil &&
        this.selectedRooms &&
        this.selectedRooms.length
      )
    }
  },
  watch: {
    async validUntil(newValue) {
      if (this.dateValidator(newValue) && newValue) {
        this.validUntil = moment(newValue)
          .tz('utc')
          .endOf('day')
          .toISOString()
        this.errors = {}
        await this.getRoomsAndSlots()
      }
    },
    async validFrom(newValue) {
      if (this.dateValidator(newValue) && newValue) {
        this.validFrom = moment(newValue)
          .tz('utc')
          .startOf('day')
          .toISOString()
        this.errors = {}
        await this.getRoomsAndSlots()
      }
    }
  },
  methods: {
    dateValidator() {
      if (moment(this.validFrom).isAfter(moment(this.validUntil))) {
        this.errors = {
          genericErrors:
            'Valid until date should before valid start date'
        }
        return false
      }
      return true
    },
    closeModal() {
      this.selectedRooms = []
      this.$bvModal.hide(this.modalId)
    },
    async getRoomsAndSlots() {
      if (
        !this.validFrom ||
        !this.validUntil ||
        this.apiCallInProgress
      ) {
        return
      }
      this.apiCallInProgress = true
      await OkkuApi.getOverLappedRoomsAndSlots({
        from: this.validFrom.split('T')[0],
        to: this.validUntil.split('T')[0]
      })
        .then(async data => {
          this.overLappedRooms = data
        })
        .catch(error => {
          let { message } = error
          if (error.response && error.response.data) {
            message = error.response.data
          }
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: message,
            variant: 'danger'
          })
        })
        .finally(() => {
          this.apiCallInProgress = false
        })
    },
    resetState() {
      this.errors = {}
      this.data = {}
      this.selectedRooms = []
      this.validUntil = null
      this.overLappedRooms = []
      this.apiCallInProgress = false
    },
    split() {
      if (!this.isFormValid) {
        this.errors = {
          genericErrors: 'All fields are mandatory'
        }
        return
      }

      this.errors = {}
      const timeslots = [].concat(
        [],
        ...this.selectedRooms.map(rooms => rooms.timeslots)
      )
      const payload = {
        valid_from: this.validFrom,
        valid_until: this.validUntil,
        timeslots
      }

      OkkuApi.upsertSplitSlots(payload)
        .then(data => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('changesSaved'),
            variant: 'success'
          })
          this.$bvModal.hide('split-slot-modal')
          this.selectedRooms = []
          this.validUntil = null
          this.$emit('done')
        })
        .catch(response => {
          if (response && response.data.errors) {
            this.errors = {}
            response.data.errors.forEach(error => {
              this.errors[error.param] = error.msg
            })
          } else {
            this.$store.commit(`common/${SET_ALERT}`, {
              seconds: 3,
              text: this.response,
              variant: 'danger'
            })
          }
        })
    }
  }
}
</script>
<style lang="scss">
#split-slot-modal {
  .vdatetime-input {
    border: none;
    width: 100%;
  }
  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .invalid-feedback {
    display: inline;
  }
}
</style>
