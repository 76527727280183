<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="tabe-responsive">
    <div v-if="hasFilter" class="filters-row  df">
      <div class="d-flex flex-wrap text-left">
        <input
          id="table-filter"
          ref="filter"
          style="width: 200px"
          type="text"
          class="form-control"
          :placeholder="$t('filterTable')"
          @keyup="filterTable"
        />
        <span v-if="hideOldTimeSlots"
          ><b-form-checkbox
            v-model="hideOldOpeningHours"
            class="m-2 slot-checkbox"
            :unchecked-value="false"
            @change="toggleOldOpeningHours($event)"
          >
            {{ $t('hideOldOpeningHours') }}
          </b-form-checkbox></span
        >
      </div>
    </div>
    <b-overlay :show="loading" rounded="sm">
      <table
        ref="table"
        class="table-style table-settings table_sort"
      >
        <thead>
          <tr>
            <th
              v-for="(column, index) in columns"
              :key="index + 'th'"
              :class="column.class"
            >
              {{ $t(column.header) }}
              <template v-if="column.hint">
                <span
                  v-b-tooltip.hover
                  class="tip-icon"
                  :title="$t(column.hint, column.hintParams)"
                >
                  <font-awesome-icon icon="question-circle" />
                </span>
              </template>
            </th>
            <th v-if="actions.length" class="global-actions">
              <span
                v-for="action in globalActions"
                :key="'action-' + action.event"
                :class="action.class"
                @click="$emit(action.event)"
              >
                <font-awesome-icon
                  v-if="action.icon"
                  :icon="action.icon"
                  class="action-icon"
                />
                {{ $t(action.name) }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, itemIdx) in allItems">
            <tr
              :key="itemIdx + '-tr'"
              :data-val="JSON.stringify(item)"
            >
              <td
                v-for="(column, colIdx) in columns"
                :key="itemIdx + '-' + colIdx + '-tr-td'"
                :class="column.class"
                v-html="
                  typeof column.transform === 'function'
                    ? column.transform(item)
                    : item[column.key]
                "
              />
              <td v-if="rowActions.length" class="actions">
                <span
                  v-for="(action, index) in rowActions"
                  :key="index"
                  :class="
                    item.clientRoles && item.clientRoles.length
                      ? isAllowedToRemoveUser(item)
                        ? action.class
                        : 'action link-edit text-base disabled'
                      : action.class
                  "
                  @click="$emit(action.event, item)"
                >
                  <font-awesome-icon
                    v-if="action.icon"
                    :icon="action.icon"
                  />{{ $t(action.name) }}
                </span>
              </td>
            </tr>
          </template>
          <div v-if="!allItems.length">
            <tr>
              <td>
                <strong>{{ $t('noRecordsFound') }}</strong>
              </td>
            </tr>
          </div>
        </tbody>
      </table>
    </b-overlay>
  </div>
</template>
<script>
import { filterTableMixin } from '@/mixins/mixins'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { TOGGLE_OLD_OPENING_HOURS } from '@/store/modules/common/mutationTypes'

export default {
  name: 'Table',
  mixins: [filterTableMixin],
  props: {
    items: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    actions: {
      type: Array,
      default() {
        return []
      }
    },
    hasFilter: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideOldTimeSlots: {
      type: Boolean,
      default: false
    },
    origionalTimeSlots: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      hideOldOpeningHours: true,
      allItems: []
    }
  },
  computed: {
    ...mapGetters('common', {
      loggedInUsersRoles: 'loggedInUsersRoles',
      oldOpeningHours: 'getOldOpeningHours'
    }),
    globalActions() {
      return this.actions.filter(action => action.global)
    },
    rowActions() {
      return this.actions.filter(action => !action.global)
    }
  },
  watch: {
    items() {
      this.allItems = [...this.items]
    }
  },
  mounted() {
    if (this.hideOldTimeSlots) {
      this.hideOldOpeningHours = this.oldOpeningHours
      this.toggleOldOpeningHours(this.oldOpeningHours)
    }
  },
  methods: {
    isAllowedToRemoveUser(editUser) {
      const editUserRoles = []
      if (editUser?.clientRoles?.length) {
        editUser.clientRoles.map(role =>
          editUserRoles.push(role.name)
        )
      }

      const logInUserRoles = this.loggedInUsersRoles
      const loggedInUserRole = this.findUserRole(logInUserRoles)

      if (!loggedInUserRole) {
        return false
      }

      const editUserRole = this.findUserRole(editUserRoles)

      if (loggedInUserRole === 'Dashboard-admin') {
        return !['Dashboard-admin'].includes(editUserRole)
      }

      if (loggedInUserRole === 'Customer-admin') {
        return !['Customer-admin', 'Dashboard-admin'].includes(
          editUserRole
        )
      }
      return false
    },
    findUserRole(userRoles) {
      if (userRoles.includes('Dashboard-admin')) {
        return 'Dashboard-admin'
      }

      if (userRoles.includes('Customer-admin')) {
        return 'Customer-admin'
      }

      return null
    },
    toggleOldOpeningHours(value) {
      const todayDate = moment().format('YYYY-MM-DD')
      if (value) {
        this.allItems = this.origionalTimeSlots?.filter(
          item =>
            !item.valid_until ||
            moment(item.valid_until).format('YYYY-MM-DD') >= todayDate
        )
      } else {
        this.allItems = this.origionalTimeSlots
      }
      this.$store.commit(`common/${TOGGLE_OLD_OPENING_HOURS}`, value)
    }
  }
}
</script>
<style lang="scss" scoped>
.global-actions {
  .action-icon {
    margin-right: 2px;
    color: white;
  }
}
.actions {
  .action {
    padding-left: 5px;
  }
}
</style>
