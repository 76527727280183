<template>
  <b-modal
    id="timeslot-modal"
    ref="bmodal"
    :on-enter-key-press="upsert"
    hide-footer
    no-close-on-backdrop
    size="xl"
    :title="title"
    @shown="preselectRoom"
    @hidden="resetRooms"
  >
    <div class="d-block text-center">
      <div class="row">
        <div class="col-md-4 col-sm-12 text-left">
          <div class="form-group">
            <label>{{ $t('room') }}:</label>
            <div>
              <multiselect
                v-model="selectedRooms"
                :options="options"
                :multiple="true"
                label="name"
                track-by="name"
                :close-on-select="true"
                placeholder="Select Rooms"
                @close="focus"
              />
            </div>

            <b-form-invalid-feedback v-if="errors.object_id">
              {{ errors['object_id'] }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 text-left">
          <div class="form-group">
            <label
              >{{ $t('startTime') }}
              <span
                v-b-tooltip.hover
                class="tip-icon"
                :title="$t('timezoneHint', { timezone })"
              >
                <font-awesome-icon icon="question-circle" />
              </span>
            </label>
            <datetime
              v-model="data.start_time"
              class="form-control inp-style"
              type="time"
              placeholder="Start Time"
              :zone="timezone"
              :value-zone="timezone"
              :minute-step="timeDifference"
              @close="onClose"
            />
            <b-form-invalid-feedback v-if="errors.start_time">
              {{ errors['start_time'] }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 text-left">
          <div class="form-group">
            <label
              >{{ $t('endTime') }}
              <span
                v-b-tooltip.hover
                class="tip-icon"
                :title="$t('timezoneHint', { timezone })"
              >
                <font-awesome-icon icon="question-circle" />
              </span>
            </label>
            <datetime
              v-model="data.end_time"
              class="form-control inp-style"
              type="time"
              placeholder="End Time"
              :zone="timezone"
              :value-zone="timezone"
              :minute-step="timeDifference"
              @close="onClose"
            />
            <p v-if="isDisabled" class="text-danger">
              {{ $t('selectGreaterStarttime') }}
            </p>
            <b-form-invalid-feedback v-if="errors.end_time">
              {{ errors['end_time'] }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 text-left">
          <div class="form-group">
            <label
              >{{ $t('validFrom') }}
              <span
                v-b-tooltip.hover
                class="tip-icon"
                :title="$t('timezoneHint', { timezone })"
              >
                <font-awesome-icon icon="question-circle" />
              </span>
            </label>
            <datetime
              v-model="data.valid_from"
              class="form-control inp-style"
              type="date"
              placeholder="Valid from"
              zone="utc"
              :minute-step="1"
            />
            <b-form-invalid-feedback v-if="errors.valid_from">
              {{ errors['valid_from'] }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 text-left">
          <div class="form-group">
            <label
              >{{ $t('validUntil') }}
              <span
                v-b-tooltip.hover
                class="tip-icon"
                :title="$t('timezoneHint', { timezone })"
              >
                <font-awesome-icon icon="question-circle" />
              </span>
            </label>
            <datetime
              v-model="data.valid_until"
              class="form-control inp-style"
              :type="'date'"
              placeholder="Valid until"
              zone="utc"
              :minute-step="1"
            />
            <div class="clear-input-wrapper">
              <font-awesome-icon
                icon="trash"
                @click="
                  () => {
                    data.valid_until = ''
                  }
                "
              />
            </div>
            <b-form-invalid-feedback v-if="errors.valid_until">
              {{ errors['valid_until'] }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 text-left">
          <div class="form-group">
            <label
              >{{ $t('timezoneId') }}
              <span
                v-b-tooltip.hover
                class="tip-icon"
                :title="$t('externalTimezoneIDHint')"
              >
                <font-awesome-icon icon="question-circle" />
              </span>
            </label>
            <b-form-input
              v-model="data.timezone_id"
              :state="errors.timezone_id ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.timezone_id">
              {{ errors.timezone_id }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-sm-12 col-md-8 text-left">
          <b-form-group label="Available on days:" inline>
            <b-form-checkbox
              v-model="data.monday"
              class="d-inline-block"
              switch
            >
              Monday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="data.tuesday"
              class="d-inline-block"
              switch
            >
              Tuesday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="data.wednesday"
              class="d-inline-block"
              switch
            >
              Wednesday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="data.thursday"
              class="d-inline-block"
              switch
            >
              Thursday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="data.friday"
              class="d-inline-block"
              switch
            >
              Friday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="data.saturday"
              class="d-inline-block"
              switch
            >
              Saturday
            </b-form-checkbox>
            <b-form-checkbox
              v-model="data.sunday"
              class="d-inline-block"
              switch
            >
              Sunday
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div class="col-md-4 col-sm-12 text-left">
          <div class="form-group">
            <label>Hint (optional)</label>
            <b-form-input
              v-model="data.hint"
              :state="errors.hint ? false : null"
            />
            <b-form-invalid-feedback v-if="errors.hint">
              {{ errors.hint }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 text-left">
          <div class="form-group">
            <label>
              {{ $t('userpools') }}:
              <button class="btn btn-sm">
                <font-awesome-icon
                  icon="plus"
                  @click="addNewUserpool"
                /></button
            ></label>
            <template v-for="(userpool, idx) in userPools">
              <div :key="`${idx}`" class="userpool-input-wrapper">
                <b-form-input v-model="userPools[idx]" />
                <font-awesome-icon
                  icon="trash"
                  @click="
                    () => {
                      userPools.splice(idx, 1)
                    }
                  "
                />
              </div>
            </template>
            <b-form-invalid-feedback v-if="errors.userpools">
              {{ errors.userpools }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
      <!-- end .row -->
      <div class="d-flex align-items-end justify-content-end">
        <b-button
          variant="danger"
          class="mt-1"
          @click="
            ;(selectedRooms = []), $bvModal.hide('timeslot-modal')
          "
        >
          {{ $t('cancel') }}
        </b-button>
        <b-button
          class="mt-1 ml-2 btn-save"
          primary
          :disabled="isDisabled"
          @click="upsert"
        >
          {{ $t('save') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { clone as _clone, cloneDeep as _deepClone } from 'lodash'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import { Datetime } from 'vue-datetime'

import OkkuApi from '@/services/OkkuApi'
import { SET_ALERT } from '@/store/modules/common/mutationTypes'

import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import Multiselect from 'vue-multiselect'
import 'vue-datetime/dist/vue-datetime.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  components: {
    Datetime,
    Multiselect,
    'b-modal': BootstrapModalHOC
  },
  props: {
    timeslot: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    data: {},
    errors: {},
    selectedRooms: [],
    identicalRooms: [],
    userPools: [],
    isDisabled: false
  }),
  computed: {
    title() {
      const prefix =
        this.mode === 'create' ? this.$t('add') : this.$t('edit')
      return `${prefix} ${this.$t('timeslot')}`
    },
    mode() {
      return this.data.id ? 'edit' : 'create'
    },
    ...mapGetters('common', {
      timezone: 'buildingTimezone',
      reservationsettings: 'reservationsettings'
    }),
    ...mapState('common', {
      rooms: 'rooms'
    }),
    options() {
      if (this.identicalRooms.length > 0) {
        return this.identicalRooms
      }
      if (this.data.id) {
        return this.selectedRooms
      }
      return this.rooms
    },
    timeDifference() {
      return this.reservationsettings?.timeslot ? 1 : 15
    }
  },
  watch: {
    'data.valid_until': function validUntil(newValue) {
      if (newValue) {
        this.data.valid_until = moment(newValue)
          .tz('utc')
          .endOf('day')
          .toISOString()
      }
    },
    'data.valid_from': function validFrom(newValue) {
      if (newValue) {
        this.data.valid_from = moment(newValue)
          .tz('utc')
          .startOf('day')
          .toISOString()
      }
    },
    timeslot: {
      deep: true,
      immediate: true,
      handler() {
        this.errors = {}
        const timeslot = _clone(this.timeslot)
        if (timeslot.start_time) {
          const [hour, minute] = timeslot.start_time.split(':')
          timeslot.start_time = moment()
            .tz(this.timezone)
            .hours(hour)
            .minute(minute)
            .toISOString()
        }
        if (timeslot.end_time) {
          const [hour, minute] = timeslot.end_time.split(':')
          timeslot.end_time = moment()
            .tz(this.timezone)
            .hours(hour)
            .minute(minute)
            .toISOString()
        }
        this.data = {
          valid_from: moment
            .utc()
            .startOf('day')
            .toISOString(),
          valid_until: '',
          start_time: moment()
            .tz(this.timezone)
            .startOf('hour')
            .hours(9)
            .toISOString(),
          end_time: moment()
            .tz(this.timezone)
            .startOf('hour')
            .hours(13)
            .toISOString(),
          userpools: [],
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false,
          ...timeslot
        }
      }
    }
  },
  methods: {
    addNewUserpool() {
      if (!Array.isArray(this.userPools)) {
        this.userPools = ['']
      } else {
        this.userPools.push('')
      }
    },
    preselectRoom() {
      if (this.mode === 'edit') {
        this.getIdenticalRooms()
      } else if (this.timeslot.id) {
        this.timeslot.name = this.timeslot.roomName
        this.selectedRooms = [this.timeslot]
      } else {
        this.selectedRooms = []
      }
      this.userPools = _deepClone(this.timeslot.userpools)
    },
    focus() {
      setTimeout(() => {
        document.querySelector('#timeslot-modal .btn-save').focus()
      }, 500)
    },
    async upsert(event) {
      if (!this.validateFormData()) {
        return
      }

      this.errors = {}
      // append selected room ids to data and call the API
      this.data.object_ids = []
      this.selectedRooms.length &&
        this.selectedRooms.forEach(selectedRoom => {
          this.data.object_ids.push({
            id:
              this.mode === 'edit'
                ? selectedRoom.object_id
                : selectedRoom.id,
            slotId: selectedRoom.slotId
          })
        })
      this.data.userpools = this.userPools
      await OkkuApi.upsertTimeslot(this.data)
        .then(data => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: data.message,
            variant: 'success'
          })
        })
        .catch(({ data }) => {
          if (data && data.errors) {
            this.errors = {}
            data.errors.forEach(error => {
              this.errors[error.param] = error.msg
            })
          } else {
            this.$store.commit(`common/${SET_ALERT}`, {
              seconds: 3,
              text: data.message,
              variant: 'danger'
            })
          }
        })
      this.$bvModal.hide('timeslot-modal')
      this.selectedRooms = []
      this.$emit('done')
    },
    async getIdenticalRooms() {
      if (!this.data || !this.data.id) {
        return
      }

      await OkkuApi.getIdenticalRooms(this.data.id)
        .then(data => {
          this.identicalRooms = data
          this.selectedRooms = data
        })
        .catch(({ response }) => {
          if (response && response.data.errors) {
            this.errors = {}
            response.data.errors.forEach(error => {
              this.errors[error.param] = error.msg
            })
          } else {
            this.$store.commit(`common/${SET_ALERT}`, {
              seconds: 3,
              text: this.response,
              variant: 'danger'
            })
          }
        })
    },
    resetRooms() {
      this.identicalRooms = []
      this.userPools = []
    },
    validateFormData() {
      if (!this.selectedRooms.length) {
        this.errors = {
          object_id: 'Please select atleast one room'
        }
        return false
      }

      if (this.data.hint && this.data.hint.length < 3) {
        this.errors = {
          hint: 'Hint should be at least 3 characters long'
        }
        return false
      }
      return true
    },
    onClose() {
      this.isDisabled = false
      if (
        moment(this.data?.start_time).isSameOrAfter(
          this.data?.end_time
        )
      ) {
        this.isDisabled = true
      }
    }
  }
}
</script>
<style lang="scss">
#timeslot-modal {
  .vdatetime-input {
    border: none;
    width: 100%;
  }
  .invalid-feedback {
    display: block;
  }
  .clear-input-wrapper {
    position: absolute;
    right: 22px;
    top: 42px;
    svg {
      cursor: pointer;
    }
  }
  .userpool-input-wrapper {
    margin-bottom: 12px;
    position: relative;
    svg {
      position: absolute;
      right: 4px;
      top: 10px;
      cursor: pointer;
    }
  }
}
</style>
