var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[(_vm.loading)?_c('div',[_c('div',{staticClass:"loader"},[_c('b-spinner',{attrs:{"label":"Spinning"}})],1)]):_c('section',{staticClass:"section-settings"},[_c('div',{staticClass:"container"},[_c('alert'),_c('b-tabs',{staticClass:"map-tabs",attrs:{"pills":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"calendar-alt"}})]},proxy:true}])},[_c('b-row',{staticClass:"calendar"},[(!_vm.isMobileDevice)?[_c('b-col',{staticClass:"mt-4",attrs:{"cols":"2"}},[_c('h6',[_vm._v(" "+_vm._s(_vm.$t('availableRooms'))+" ")]),_c('div',{staticClass:"filters scrollableContent"},[_c('div',[_c('b-form-checkbox',{staticClass:"m-2 slot-checkbox",attrs:{"id":"checkboxAll","name":"checkboxAll","value":"true","unchecked-value":"false"},on:{"change":function($event){return _vm.toggleAll()}},model:{value:(_vm.checkAllFlag),callback:function ($$v) {_vm.checkAllFlag=$$v},expression:"checkAllFlag"}},[_vm._v(" "+_vm._s(_vm.$t('selectAll'))+" ")]),_vm._l((_vm.selectedSlots),function(selectedSlot){return _c('b-form-checkbox',{key:selectedSlot.id,staticClass:"m-2 slot-checkbox",style:({
                        '--color': selectedSlot.backgroundColor
                      }),on:{"input":function($event){return _vm.selectedFilterValues(
                          selectedSlot.roomName,
                          $event
                        )}},model:{value:(selectedSlot.checked),callback:function ($$v) {_vm.$set(selectedSlot, "checked", $$v)},expression:"selectedSlot.checked"}},[_c('span',{staticClass:"option-label"},[_vm._v(" "+_vm._s(selectedSlot.roomName)+" ")])])})],2)])]),_c('b-col',{attrs:{"cols":"10"}},[(_vm.activeTab === 0)?_c('CalendarView',{attrs:{"slots":_vm.timeslots},on:{"create-event":_vm.createTimeslot,"edit-event":_vm.editTimeslot}}):_vm._e()],1)]:_c('div',{staticClass:"mt-4 p-4"},[_vm._v(" "+_vm._s(_vm.$t('calendarViewErrorMessage'))+" ")])],2)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"list"}})]},proxy:true}])},[_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_c('timeslots-table',{attrs:{"items":_vm.filteredTimeSlots,"columns":_vm.columns,"actions":_vm.actions,"hide-old-time-slots":true,"origional-time-slots":_vm.timeslots},on:{"edit":_vm.editTimeslot,"delete":function (data) {
                      _vm.timeslot = data
                      _vm.$bvModal.show('modal-remove')
                    },"create":_vm.createTimeslot}})],1)]),_c('timeslot-modal',{attrs:{"timeslot":_vm.timeslot},on:{"done":_vm.getTimeslots}}),_c('split-slot-modal',{ref:"splitSlotModal",attrs:{"valid-from":_vm.currentCalendarDate},on:{"done":_vm.getTimeslots}}),_c('choice-modal',{on:{"edit":_vm.showEditModal,"split":_vm.showSplitModal}}),_c('b-modal',{attrs:{"id":"modal-remove","on-enter-key-press":function () { return _vm.deleteTimeslot(_vm.timeslot); },"hide-footer":"","title":_vm.$t('remove')}},[_c('div',{staticClass:"d-block text-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('deleteTimeslotConfirmation'))+" ")])]),_c('div',{staticClass:"d-flex justify-content-center mt-1"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$bvModal.hide('modal-remove')}}},[_vm._v(" "+_vm._s(_vm.$t('no'))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"primary":""},on:{"click":function($event){return _vm.deleteTimeslot(_vm.timeslot)}}},[_vm._v(" "+_vm._s(_vm.$t('yes'))+" ")])],1)])],1)])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }